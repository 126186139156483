import React from 'react'
import {
  EyeClosed,
  TreeStructure,
  Copy,
  AlignBottom,
  AlignBottomSimple,
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignCenterVerticalSimple,
  AlignLeft,
  AlignRight,
  AlignTop,
  AlignTopSimple,
  ArrowArcLeft,
  ArrowArcRight,
  ArrowClockwise,
  ArrowFatUp,
  ArrowSquareRight,
  ArrowSquareUpRight,
  At,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  CaretUpDown,
  ChatTeardropText,
  Check,
  CirclesFour,
  ClockCounterClockwise,
  Columns,
  Command,
  Control,
  Cube,
  Devices,
  DotsSixVertical,
  DotsThreeVertical,
  Eye,
  EyeSlash,
  File,
  Folder,
  Funnel,
  GearSix,
  HouseLine,
  ImageSquare,
  ImagesSquare,
  Info,
  Key,
  Lightning,
  Link,
  LinkBreak,
  LinkSimple,
  ListBullets,
  MagnifyingGlass,
  Minus,
  NotePencil,
  PencilSimple,
  PencilSimpleLine,
  Pinwheel,
  Play,
  Plus,
  PlusCircle,
  Rectangle,
  RocketLaunch,
  Rows,
  ShareNetwork,
  ShoppingCart,
  SlidersHorizontal,
  Slideshow,
  SmileySad,
  SquareSplitHorizontal,
  SquaresFour,
  StackSimple,
  Swap,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
  TextB,
  TextHFive,
  TextHFour,
  TextHOne,
  TextHSix,
  TextHThree,
  TextHTwo,
  TextItalic,
  TextT,
  TextUnderline,
  Upload,
  User,
  UserPlus,
  UserSwitch,
  X,
  CheckCircle,
  XCircle,
  Warning,
  CheckSquare,
  ThumbsUp,
  Trash,
  ArrowRight,
  ArrowsCounterClockwise,
  Confetti,
  Star,
  Heart,
  MagicWand,
  Rocket,
  Fire,
  PaperPlaneTilt,
  ShareFat,
  Envelope,
  EnvelopeSimple,
  Phone,
  ChatsCircle,
  ChatCircle,
  Car,
  AirplaneTilt,
  ShieldCheck,
  SealCheck,
  Lock,
  FingerprintSimple,
  Detective,
  FolderOpen,
  PushPin,
  MusicNotesSimple,
  Camera,
  Microphone,
  FilmStrip,
  CloudArrowUp,
  CloudArrowDown,
  Lightbulb,
  Package,
  CalendarBlank,
  CalendarPlus,
  Alarm,
  Timer,
  Clock,
  ClockClockwise,
  ClockCountdown,
  ShoppingCartSimple,
  Basket,
  Tote,
  ShoppingBag,
  BellRinging,
  GlobeHemisphereWest,
  GlobeHemisphereEast,
  GlobeSimple,
  NavigationArrow,
  Compass,
  TwitterLogo,
  InstagramLogo,
  LinkedinLogo,
  FacebookLogo,
  TiktokLogo,
  YoutubeLogo,
  RedditLogo,
  GithubLogo,
  SlackLogo,
  DiscordLogo,
  AndroidLogo,
  GooglePlayLogo,
  AppleLogo,
  AppStoreLogo,
  StackOverflowLogo,
  SpotifyLogo,
  SoundcloudLogo,
  AmazonLogo,
  PaypalLogo,
  Bank,
  ChartLine,
  ChartBar,
  ChartPie,
  ChartDonut,
  MathOperations,
  House,
  GraduationCap,
  PiggyBank,
  BookmarkSimple,
  Flag,
  Recycle,
  Pill,
  HandHeart,
  FirstAid,
  UserCirclePlus,
  TrashSimple,
  Pen,
  DeviceTabletSpeaker,
  DeviceMobileSpeaker,
  CopySimple,
  TextStrikethrough,
  ListNumbers,
  Sparkle,
  EnvelopeSimpleOpen,
  Textbox,
  CursorText,
  HandPointing,
  Files,
  FileText,
  ArrowLeft,
  DotsThree,
  PlusSquare,
  SignOut,
  Wrench,
  UploadSimple,
  Sticker,
  Crosshair,
  Desktop,
  DeviceTabletCamera,
  CheckSquareOffset,
  GearFine,
  PuzzlePiece,
  CloudSlash,
  CloudCheck,
  ArrowUpRight,
  PencilSimpleSlash,
  DownloadSimple,
  CaretDoubleLeft,
  Users,
  AsteriskSimple,
  WarningCircle,
  TextAa,
  Palette,
  Buildings,
  List,
  Question,
} from '@phosphor-icons/react'

export const PHOSPHOR_ICONS = {
  EyeClosed,
  TreeStructure,
  Copy,
  WarningCircle,
  AlignBottom,
  Crosshair,
  AlignBottomSimple,
  AlignCenterHorizontal,
  AlignCenterVertical,
  AlignCenterVerticalSimple,
  AlignLeft,
  AlignRight,
  AlignTop,
  AlignTopSimple,
  ArrowArcLeft,
  ArrowArcRight,
  ArrowClockwise,
  ArrowFatUp,
  ArrowSquareRight,
  ArrowSquareUpRight,
  At,
  CaretDown,
  CaretLeft,
  CaretRight,
  CaretUp,
  CaretUpDown,
  ChatTeardropText,
  Check,
  CirclesFour,
  ClockCounterClockwise,
  Columns,
  Command,
  Control,
  CopySimple,
  Cube,
  Devices,
  DotsSixVertical,
  DotsThreeVertical,
  Eye,
  EyeSlash,
  File,
  Folder,
  Funnel,
  GearSix,
  House,
  HouseLine,
  ImageSquare,
  ImagesSquare,
  Info,
  Key,
  Lightning,
  Link,
  LinkBreak,
  LinkSimple,
  ListBullets,
  MagnifyingGlass,
  Minus,
  NotePencil,
  PencilSimple,
  PencilSimpleLine,
  Pinwheel,
  Play,
  Plus,
  PlusCircle,
  Rectangle,
  Recycle,
  RocketLaunch,
  Rows,
  ShareNetwork,
  ShoppingCart,
  SlidersHorizontal,
  Slideshow,
  SmileySad,
  SquareSplitHorizontal,
  SquaresFour,
  StackSimple,
  Swap,
  TextAlignCenter,
  TextAlignJustify,
  TextAlignLeft,
  TextAlignRight,
  TextB,
  TextHFive,
  TextHFour,
  TextHOne,
  TextHSix,
  TextHThree,
  TextHTwo,
  TextItalic,
  TextT,
  TextUnderline,
  Trash,
  Upload,
  User,
  UserPlus,
  UserSwitch,
  Warning,
  X,
  CheckCircle,
  XCircle,
  CheckSquare,
  ThumbsUp,
  ArrowRight,
  ArrowsCounterClockwise,
  Confetti,
  Star,
  Heart,
  MagicWand,
  Rocket,
  Fire,
  PaperPlaneTilt,
  ShareFat,
  Envelope,
  EnvelopeSimple,
  Phone,
  ChatsCircle,
  ChatCircle,
  Car,
  AirplaneTilt,
  ShieldCheck,
  SealCheck,
  Lock,
  FingerprintSimple,
  Detective,
  FolderOpen,
  PushPin,
  MusicNotesSimple,
  Camera,
  Microphone,
  FilmStrip,
  CloudArrowUp,
  CloudArrowDown,
  Lightbulb,
  Package,
  CalendarBlank,
  CalendarPlus,
  Alarm,
  Timer,
  Clock,
  ClockClockwise,
  ClockCountdown,
  ShoppingCartSimple,
  Basket,
  Tote,
  ShoppingBag,
  BellRinging,
  GlobeHemisphereWest,
  GlobeHemisphereEast,
  GlobeSimple,
  NavigationArrow,
  Compass,
  TwitterLogo,
  InstagramLogo,
  LinkedinLogo,
  FacebookLogo,
  TiktokLogo,
  YoutubeLogo,
  RedditLogo,
  GithubLogo,
  SlackLogo,
  DiscordLogo,
  AndroidLogo,
  GooglePlayLogo,
  AppleLogo,
  AppStoreLogo,
  StackOverflowLogo,
  SpotifyLogo,
  SoundcloudLogo,
  AmazonLogo,
  PaypalLogo,
  Bank,
  ChartLine,
  ChartBar,
  ChartPie,
  ChartDonut,
  MathOperations,
  GraduationCap,
  PiggyBank,
  BookmarkSimple,
  Flag,
  Pill,
  HandHeart,
  FirstAid,
  UserCirclePlus,
  TrashSimple,
  Pen,
  DeviceTabletSpeaker,
  DeviceMobileSpeaker,
  TextStrikethrough,
  ListNumbers,
  Sparkle,
  EnvelopeSimpleOpen,
  Textbox,
  CursorText,
  HandPointing,
  Files,
  FileText,
  ArrowLeft,
  DotsThree,
  PlusSquare,
  SignOut,
  Wrench,
  UploadSimple,
  Sticker,
  Desktop,
  DeviceTabletCamera,
  CheckSquareOffset,
  GearFine,
  PuzzlePiece,
  CloudSlash,
  CloudCheck,
  ArrowUpRight,
  PencilSimpleSlash,
  DownloadSimple,
  CaretDoubleLeft,
  Users,
  AsteriskSimple,
  Palette,
  TextAa,
  Buildings,
  List,
  Question,
}

export type PhosphorIconProps = {
  className?: string
  style?: React.CSSProperties
  icon: keyof typeof PHOSPHOR_ICONS
  weight?: 'thin' | 'light' | 'regular' | 'bold' | 'fill' | 'duotone'
  alt?: string
  color?: string
  size?: string | number
  mirrored?: boolean
}

export function PhosphorIcon({
  icon,
  className,
  style,
  weight,
  alt,
  color,
  size = 16,
  mirrored,
}: PhosphorIconProps) {
  const Icon = PHOSPHOR_ICONS[icon]

  if (!Icon) {
    console.error(`Phosphor icon not found: ${icon}`)
    return <span>⨂</span>
  }

  return (
    <Icon
      className={className}
      style={style}
      weight={weight}
      alt={alt}
      color={color}
      size={size}
      mirrored={mirrored}
    />
  )
}
