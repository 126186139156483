import { Client, fetchExchange } from '@urql/core'

import type {
  DocumentName,
  GetVarsByDocumentName,
  GqlResultByDocumentName,
  GraphqlClientOptions,
} from './types'
import { normalizeGraphqlUrl } from './utils'
import { loadDocumentOrThrow } from './documentHandler'
import { createTimeHeaders } from './security/createTimeHeaders'
import { type ServerVars, runServerValidator } from './security/serverValidator'
import { version as graphqlVersion } from '../generated/config'

const noop = () => {}

const CHECK_INTERVAL = 60_000 // 1 minute
const REFRESH_INTERVAL = 10_000
const REFRESH_KEY_NAME = 'lastHashErrorRefreshAt'
const HASH_ERROR_STATUS_CODE = 409

let lastServerCheckAt = 0
let serverVars: Promise<ServerVars> | null = null

let clientInstance: Client

let commonToken: GraphqlClientOptions['token'] = undefined
let commonUrl: GraphqlClientOptions['url'] = undefined
let commonHeaders: GraphqlClientOptions['headers'] = undefined
let commonOnQueryStart: GraphqlClientOptions['onQueryStart'] = noop
let commonOnQueryEnd: GraphqlClientOptions['onQueryEnd'] = noop

export function setGraphqlClientCommonOptions(options: GraphqlClientOptions) {
  const {
    url: presetUrl,
    token: presetToken,
    onQueryStart: presetOnQueryStart,
    onQueryEnd: presetOnQueryEnd,
    headers: presetHeaders,
  } = options || {}
  if (presetUrl) commonUrl = presetUrl
  if (presetToken) commonToken = presetToken
  if (presetHeaders) commonHeaders = presetHeaders
  if (presetOnQueryStart) commonOnQueryStart = presetOnQueryStart
  if (presetOnQueryEnd) commonOnQueryEnd = presetOnQueryEnd
}

export function resetGraphqlClientCommonOptions() {
  commonUrl = undefined
  commonToken = undefined
  commonHeaders = {}
  commonOnQueryStart = noop
  commonOnQueryEnd = noop
}

export function graphqlClientFactory(options?: GraphqlClientOptions) {
  const {
    url: clientUrl,
    token: clientToken,
    headers: clientHeaders,
    onQueryStart: clientOnQueryStart,
    onQueryEnd: clientOnQueryEnd,
  } = options || {}

  const url = clientUrl || commonUrl

  if (!url) {
    throw Error(`URL is not set!`)
  }

  if (!clientInstance) {
    clientInstance = new Client({
      url: normalizeGraphqlUrl(url),
      exchanges: [fetchExchange],
      requestPolicy: 'network-only',
      preferGetMethod: false,
    })
  }

  async function sendGraphql<
    T extends DocumentName,
    Vars extends GetVarsByDocumentName<T>,
  >(
    documentName: T,
    vars: Vars,
    options?: Omit<GraphqlClientOptions<T>, 'url'>,
  ) {
    const {
      token: requestToken,
      headers: requestHeaders,
      onQueryStart: requestOnQueryStart,
      onQueryEnd: requestOnQueryEnd,
    } = options || {}

    const now = Date.now()
    const { document, info } = loadDocumentOrThrow(documentName)

    const context = {
      fetchOptions() {
        return {
          cache: 'no-cache',
          headers: {
            // ...createTimeHeaders(),
            authorization: `Bearer ${
              requestToken ||
              clientToken ||
              commonToken ||
              '<<< undefined token >>>'
            }`,
            'x-graqhql-version': graphqlVersion,
            'x-graphql-client': 'InternalGraphqlClient',
            'x-graphql-query': documentName.replace(/Document$/, ''),
            'x-graphql-hash': info.hash,
            'x-graphql-operation': info.operation,
            'x-req-ts': Date.now().toString(),
            'x-req-tz': Intl.DateTimeFormat().resolvedOptions().timeZone,

            ...(requestHeaders || clientHeaders || commonHeaders || {}),
          },
        }
      },
    } satisfies Parameters<typeof clientInstance.query>[2]

    // prettier-ignore
    const onQueryStart = requestOnQueryStart || clientOnQueryStart || commonOnQueryStart
    const onQueryEnd = requestOnQueryEnd || clientOnQueryEnd || commonOnQueryEnd

    if (!serverVars || now - lastServerCheckAt > CHECK_INTERVAL) {
      lastServerCheckAt = now
      try {
        serverVars = runServerValidator(url!)
      } catch (err) {
        console.error(err)

        return
      }
    }

    // wait for server check
    await serverVars

    // callback before
    onQueryStart &&
      onQueryStart({ documentName, variables: vars, timestamp: new Date() })

    // send request
    const res = (await clientInstance[info.operation as 'query' | 'mutation'](
      // `query{version}`,
      document,
      vars as any,
      context,
    ).toPromise()) as GqlResultByDocumentName<T>

    // refresh browser if query hash is not valid
    if (res.error && res.error.response.status === HASH_ERROR_STATUS_CODE) {
      const lastRefreshAt = Number(localStorage.getItem(REFRESH_KEY_NAME))

      if (now - lastRefreshAt > REFRESH_INTERVAL) {
        localStorage.setItem(REFRESH_KEY_NAME, now.toString())
        location.reload()
      }
    }

    // callback after
    onQueryEnd &&
      onQueryEnd({
        documentName,
        variables: vars,
        timestamp: new Date(),
        result: res,
      })

    return res
  }

  return {
    graphql: sendGraphql,
  }
}
